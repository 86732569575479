import { State } from '../state';
import { ActionContext } from 'vuex'
import ReportItemData from '@/store/packages/ReportItem/ReportItemData'
import { PLATAFORM_TYPE } from '@/store/catalogs/PLATAFORM_TYPE';
import { RouteDestination } from '../../geofences';
import intersection from '@/utils/intersection';
import { getResZonesDataByIds } from '@/utils/wialonSDK/getZonesDataByIds';
import { Geofence, ICoordenadas, ResourceGeofences } from '@/store/interfaces/Geofences';
import { GEOFENCES_TYPE } from '@/store/catalogs/GEOFENCES_TYPES';
import AObject from '@/submodules/maponjssdk/dist/classes/actions/AObjects.action';
import pip from 'point-in-polygon'
import circleToPolygon from 'circle-to-polygon'
import { isPointOnLinearGeofence } from '@/utils/isPointOnLinearGeofence';
import analitics from './analitics';

export default {
  changeTab (context: ActionContext<State, string>) {
    context.dispatch('sys/changeMonitTab', 'tab-Maps', { root: true })
    setTimeout(() => {
      context.state.$ref.invalidateSize()
    }, 100)
  },
  fitBounds (context: ActionContext<State, string>, bounds: number[][]) {
    // @ts-ignore
    if (!context.state.$ref || context.rootState.sys.currentTab !== 'tab-Maps') {
      context.dispatch('changeTab')
    }
    context.state.$ref.mapObject.fitBounds(bounds)
  },
  center (context: ActionContext<State, string>, coors: { lat?: number; lng?: number }) {
    // @ts-ignore
    if (!context.state.$ref || context.rootState.sys.currentTab !== 'tab-Maps') {
      context.dispatch('changeTab')
    }
    if (!coors.lat || !coors.lng) return
    context.state.$ref.center(coors)
  },
  zoom (context: ActionContext<State, string>, zoom: number) {
    // @ts-ignore
    if (!context.state.$ref || context.rootState.sys.currentTab !== 'tab-Maps') {
      context.dispatch('changeTab')
    }
    context.state.$ref.zoom(zoom)
  },
  cleanMarkers (context: ActionContext<State, string>) {
    context.commit('SET_MARKERS', [])
    context.commit('SET_STATE', { unitsAnaliticsData: {} })
    // context.commit('SET_COMMON_PLACES_STATE', { places: [], reportItem: null })
  },
  async getRouteDestinationByReportItem ({ rootState }: ActionContext<State, string>, { report_item }: { report_item: ReportItemData<unknown, unknown> }) {
    if (!report_item) return []

    const destinations = Object.assign({}, report_item.last_data.DESTINATION)

    if (!destinations.route_destinations.length) {
      return []
    }
    // @ts-ignore
    const routeDestinationsByGroup = (rootState.geofences.routesDestinationsGeofences as { id_group: number; id_plataform: number; route_destinations: Array<RouteDestination> }[]).find((rd) => rd.id_group === report_item.id_group)
    // @ts-ignore
    const consoleGeofencesByGroup: ResourceGeofences | undefined = rootState.geofences.geofences.find(obj => obj.pType === PLATAFORM_TYPE.CONSOLA && obj.id === report_item.id_group)

    const resPlataforGeofences = report_item.getResPlataformGeofences()

    const reportItemRoutesDestinations = intersection(routeDestinationsByGroup?.route_destinations ?? [], destinations.route_destinations, (rd, rdi) => {
      return rd.id_route_destinations === rdi.id_route_destinations;
    });

    switch (report_item.ri_type) {
      case PLATAFORM_TYPE.WIALON: {
        // @ts-ignore
        const resource = rootState.wialon.resources.find(obj => `${obj._id}` === `${report_item.gp_wialon_id}`)
        if (!resource) return []

        const routesDestinationsWithGeofences = await Promise.all(reportItemRoutesDestinations.map(async rd => {
          const rd_geofences_ids_wialon = rd.rd_geofences.filter(geo => geo.pType === PLATAFORM_TYPE.WIALON || !geo.pType).map(geo => geo.id)
          const rd_geofences_ids_consola = rd.rd_geofences.filter(geo => geo.pType === PLATAFORM_TYPE.CONSOLA).map(geo => geo.id)
          // @ts-ignore
          const wialonGeofences: Geofence[] = (await getResZonesDataByIds(resource, rd_geofences_ids_wialon)).map(obj => ({ ...obj, pType: PLATAFORM_TYPE.WIALON, p: obj.t === GEOFENCES_TYPE.CIRCLE ? { lat: obj.p.at(0)?.y, lng: obj.p.at(0)?.x, radius: obj.p.at(0)?.r } : (obj.p ?? []).map(coor => ({ lat: coor.y, lng: coor.x })) }))
          const consoleGeofences = intersection(consoleGeofencesByGroup?.geofences ?? [], rd_geofences_ids_consola, (geofence, id) => geofence.id === id)
          return {
            ...rd,
            geofences: [...wialonGeofences, ...consoleGeofences]
          }
        }))
        return routesDestinationsWithGeofences
      }
      case PLATAFORM_TYPE.MAPON: {
        // @ts-ignore
        const maponObjects: AObject[] = rootState.mapon.objects
        const routesDestinationsWithGeofences = await Promise.all(reportItemRoutesDestinations.map(async rd => {
          const rd_geofences_ids_mapon = rd.rd_geofences.filter(geo => geo.pType === PLATAFORM_TYPE.MAPON || geo.pType === undefined).map(geo => geo.id)
          const rd_geofences_ids_consola = rd.rd_geofences.filter(geo => geo.pType === PLATAFORM_TYPE.CONSOLA).map(geo => geo.id)
          const maponGeofences: Geofence[] = resPlataforGeofences.reduce((prev, curr) => {
            if (!rd_geofences_ids_mapon.includes(curr.id)) return prev
            const object = maponObjects.find(obj => obj.id === curr.id)
            if (!object) return prev
            const currGeofeceData: Geofence = {
              ...curr,
              // @ts-ignore
              p: object.getPoints().map(arr => ({ lat: arr.at(0), lng: arr.at(1) })),
              pType: PLATAFORM_TYPE.MAPON
            }
            prev.push(currGeofeceData)
            return prev
          }, [] as Geofence[])
          const consoleGeofences = intersection(consoleGeofencesByGroup?.geofences ?? [], rd_geofences_ids_consola, (geofence, id) => geofence.id === id)
          return {
            ...rd,
            geofences: [...maponGeofences, ...consoleGeofences]
          }
        }))

        return routesDestinationsWithGeofences
      }
    }

    return []
  },
  async getGeofencesByReportItem ({ rootState }: ActionContext<State, string>, { report_item }: { report_item: ReportItemData<unknown, unknown> }) {
    if (!report_item) return []

    const destinations = Object.assign({}, report_item.last_data.DESTINATION)

    if (!destinations.geofences.length) {
      return []
    }
    // @ts-ignore
    const consoleGeofencesByGroup: ResourceGeofences | undefined = rootState.geofences.geofences.find(obj => obj.pType === PLATAFORM_TYPE.CONSOLA && obj.id === report_item.id_group)

    const resPlataforGeofences = report_item.getResPlataformGeofences()

    const consoleGeofencesSelected = intersection(consoleGeofencesByGroup?.geofences ?? [], destinations.geofences.filter(geo => geo.pType === PLATAFORM_TYPE.CONSOLA), (geo1, geo2) => {
      return geo1.id === geo2.id
    })

    switch (report_item.ri_type) {
      case PLATAFORM_TYPE.WIALON: {
        // @ts-ignore
        const resource = rootState.wialon.resources.find(obj => `${obj._id}` === `${report_item.gp_wialon_id}`)
        if (!resource) return []

        const wialonGeofencesSelectedIds = intersection(destinations.geofences.filter(geo => geo.pType === PLATAFORM_TYPE.WIALON || !geo.pType).map(geo => geo.id), resPlataforGeofences, (id, geo) => geo.id === id)
        // @ts-ignore
        const wialonGeofencesWithPoints: Geofence[] = (await getResZonesDataByIds(resource, wialonGeofencesSelectedIds)).map(obj => ({ ...obj, pType: PLATAFORM_TYPE.WIALON, p: obj.t === GEOFENCES_TYPE.CIRCLE ? { lat: obj.p.at(0)?.y, lng: obj.p.at(0)?.x, radius: obj.p.at(0)?.r } : (obj.p ?? []).map(coor => ({ lat: coor.y, lng: coor.x })) }))

        const geofences = [...consoleGeofencesSelected, ...wialonGeofencesWithPoints]

        return geofences
      }
      case PLATAFORM_TYPE.MAPON: {
        // @ts-ignore
        const maponObjects: AObject[] = rootState.mapon.objects

        const maponGeofencesSelectedIds = intersection(destinations.geofences.filter(geo => geo.pType === PLATAFORM_TYPE.MAPON || geo.pType === undefined).map(geo => geo.id), resPlataforGeofences, (id, geo) => geo.id === id)

        const maponGeofencesWithPoints: Geofence[] = resPlataforGeofences.reduce((prev, curr) => {
          if (!maponGeofencesSelectedIds.includes(curr.id)) return prev
          const object = maponObjects.find(obj => obj.id === curr.id)
          if (!object) return prev
          const currGeofeceData: Geofence = {
            ...curr,
            // @ts-ignore
            p: object.getPoints().map(arr => ({ lat: arr.at(0), lng: arr.at(1) })),
            pType: PLATAFORM_TYPE.MAPON
          }
          prev.push(currGeofeceData)
          return prev
        }, [] as Geofence[])

        const geofences = [...consoleGeofencesSelected, ...maponGeofencesWithPoints]

        return geofences
      }
    }

    return []
  },
  async getGeofencesAndRDByReportItem ({ dispatch }: ActionContext<State, string>, { report_item }: { report_item: ReportItemData<unknown, unknown> }) {
    if (!report_item) return { geofences: [], routeDestinations: [] }

    const geofences = await dispatch('getGeofencesByReportItem', { report_item })
    const routeDestinations = await dispatch('getRouteDestinationByReportItem', { report_item })

    return { geofences, routeDestinations }
  },
  async displayRIOnMap ({ dispatch, commit }: ActionContext<State, string>, { id_report_item }: { id_report_item: number }) {
    const report_item: ReportItemData<unknown, unknown> = await dispatch('reports/reportItemById', id_report_item, { root: true })
    if (!report_item) return
    report_item.showOnMap = true

    dispatch('center', { lat: report_item.last_message?.pos?.y, lng: report_item.last_message?.pos?.x })
    commit('SET_STATE', { curr_id_report_item: report_item.id_report_item })

    const { geofences, routeDestinations }: { geofences: Geofence[], routeDestinations: RouteDestination[] } = await dispatch('getGeofencesAndRDByReportItem', { report_item })

    commit('SET_STATE', { selectedRouteGeofences: routeDestinations })
    commit('SET_STATE', { selectedGeofences: geofences })

    commit('UPDATE_OBJS_KEY')
  },
  getMatchesGeofecesByPayload (_: ActionContext<State, string>, { lat, lng, geofences }: { lat: number; lng: number; geofences: Geofence[] }) {
    const currGeofences = geofences.filter((geofence) => {
      const geofenceType = geofence?.geo_payload?.type ?? geofence.t
      const coors = geofence.pType === PLATAFORM_TYPE.CONSOLA ? geofence?.geo_payload?.coordinates : geofence.p
      // @ts-ignore
      const radius = geofence.pType === PLATAFORM_TYPE.CONSOLA ? geofence?.geo_payload?.radius : geofence.p?.radius
      const width = geofence.w

      switch (geofenceType) {
        case GEOFENCES_TYPE.POLYGON: {
          const isInside = pip([lat, lng], (coors as ICoordenadas[]).map(coor => [coor.lat, coor.lng]))
          return isInside
        }
        case GEOFENCES_TYPE.CIRCLE: {
          if (!radius || radius === 0) return false
          // @ts-ignore
          const poligon = circleToPolygon([coors?.lng, coors?.lat], radius);
          const points: number[][] = poligon.coordinates[0].map(coor => [coor[1], coor[0]])
          const isInside = pip([lat, lng], points)
          return isInside
        }
        case GEOFENCES_TYPE.LINE: {
          // @ts-ignore
          const isInside = isPointOnLinearGeofence(lat, lng, (coors as ICoordenadas[]).map(coor => [coor.lng, coor.lat]), width)
          return isInside
        }
      }

      return false
    })
    return currGeofences
  },
  async getMatchesRDByPayload ({ dispatch }: ActionContext<State, string>, { lat, lng, routeDestinations }: { lat: number; lng: number; routeDestinations: RouteDestination[] }) {
    const currRouteGeofences = await Promise.all(routeDestinations.filter(async routeGeofence => {
      const matchGeofences = (await dispatch('getMatchesGeofecesByPayload', { lat, lng, geofences: routeGeofence.geofences })) as Geofence[]
      return matchGeofences.length
    }))

    return currRouteGeofences
  },
  async getMatchesStateGeofences ({ state, dispatch }: ActionContext<State, string>, { lat, lng }: { lat: number; lng: number }) {
    const matchesGeofences = (await dispatch('getMatchesGeofecesByPayload', { lat, lng, geofences: state.selectedGeofences })) as Geofence[]
    return matchesGeofences
  },
  async getMatchesStateRouteGeofences ({ state, dispatch }: ActionContext<State, string>, { lat, lng }: { lat: number; lng: number}) {
    const currRouteGeofences = await Promise.all(state.selectedRouteGeofences.filter(async routeGeofence => {
      const matchGeofences = (await dispatch('getMatchesGeofecesByPayload', { lat, lng, geofences: routeGeofence.geofences })) as Geofence[]
      return matchGeofences.length
    }))

    return currRouteGeofences
  },
  ...analitics
}
